import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '@hss-m/uikit-common';
import { LOCAL_STORAGE } from '../app.module';
@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {

  baseUrl = `${environment.baseUrl}`;

  constructor(private lsc: LocalStorageService) { }

  getBaseUrl(): string {
    const fromStorage = this.lsc.get(LOCAL_STORAGE.APP_BASE_URL)
    return fromStorage ? `${fromStorage}` : this.baseUrl;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const baseUrl = this.getBaseUrl();
    let url = request.url;
    if(!url.includes(baseUrl)){
      url = `${baseUrl}/${url}`;
    }
    const authReq = request.clone({url});
    return next.handle(authReq);
  }

}
